import { Button, Dialog, Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import { PhoneNumber } from '@eppendorf/vnls-user-tenant-utils';
import { ReactElement, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { getDisplayPhoneNumber } from '$features/edit-user/utils/get-display-phone-number';

export type PhoneNumberUpdatedDialogState = {
  isOpen: boolean;
  currentPhoneNumbers?: PhoneNumber[];
  newPhoneNumbers?: PhoneNumber[];
  allowSmsCommunication?: boolean;
};

export type PhoneNumberUpdatedDialogProps = PhoneNumberUpdatedDialogState & {
  onOpenChange: (open: boolean) => void;
  onVerifyPhoneNumber: () => void;
};

export function PhoneNumberUpdatedDialog({
  isOpen = false,
  onOpenChange,
  onVerifyPhoneNumber,
  currentPhoneNumbers,
  newPhoneNumbers,
  allowSmsCommunication,
}: PhoneNumberUpdatedDialogProps): ReactElement {
  const { t } = useTranslation();

  const fromToValues = useMemo(
    () => ({
      fromPhoneNumber: getDisplayPhoneNumber(currentPhoneNumbers),
      toPhoneNumber: getDisplayPhoneNumber(newPhoneNumbers),
    }),
    [currentPhoneNumbers, newPhoneNumbers],
  );

  return (
    <Dialog isOpen={isOpen} onOpenChange={onOpenChange} className="w-max-4xl">
      <div className="flex flex__dir--column flex__ai--center text-align-center">
        <Icon
          name="success"
          className="bg-green-500 m-bottom-xxl"
          size={IconSizeClasses.XLarge}
        />
        <Dialog.Title>{t('phoneNumberUpdatedDialog.title')}</Dialog.Title>
        <div>
          <p className="block m-bottom-l">
            <Trans
              i18nKey="phoneNumberUpdatedDialog.description"
              values={fromToValues}
              components={{ bold: <span className="font-weight-bold" /> }}
            />
          </p>
          <p>
            <Trans
              i18nKey={
                allowSmsCommunication
                  ? 'phoneNumberUpdatedDialog.smsNotificationsDisabled'
                  : 'phoneNumberUpdatedDialog.enableSMSCommunication'
              }
            />
          </p>
        </div>
      </div>
      <Dialog.Actions>
        <Dialog.Close>
          <Button variant="secondary" className="btn--solid">
            {t('shared.close')}
          </Button>
        </Dialog.Close>
        <Button onClick={onVerifyPhoneNumber}>{t('shared.verifyNumber')}</Button>
      </Dialog.Actions>
    </Dialog>
  );
}
