import { Button, Dialog, Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import { PhoneNumber } from '@eppendorf/vnls-user-tenant-utils';
import { ReactElement, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { getDisplayPhoneNumber } from '$features/edit-user/utils/get-display-phone-number';

export type PhoneNumberRemovedDialogState = {
  isOpen: boolean;
  currentPhoneNumbers?: PhoneNumber[];
  allowSmsCommunication?: boolean;
};

export type PhoneNumberRemovedDialogProps = PhoneNumberRemovedDialogState & {
  onOpenChange: (open: boolean) => void;
};

export function PhoneNumberRemovedDialog({
  isOpen = false,
  onOpenChange,
  currentPhoneNumbers,
  allowSmsCommunication,
}: PhoneNumberRemovedDialogProps): ReactElement {
  const { t } = useTranslation();

  const phoneNumberValue = useMemo(
    () => ({
      phoneNumber: getDisplayPhoneNumber(currentPhoneNumbers),
    }),
    [currentPhoneNumbers],
  );

  return (
    <Dialog isOpen={isOpen} onOpenChange={onOpenChange} className="w-max-4xl">
      <div className="flex flex__dir--column flex__ai--center text-align-center">
        <Icon
          name="success"
          className="bg-green-500 m-bottom-xxl"
          size={IconSizeClasses.XLarge}
        />
        <Dialog.Title>{t('phoneNumberRemovedDialog.title')}</Dialog.Title>
        <div>
          <p className="block m-bottom-l">
            <Trans
              i18nKey="phoneNumberRemovedDialog.description"
              values={phoneNumberValue}
              components={{ bold: <span className="font-weight-bold" /> }}
            />
          </p>
          <p>
            <Trans
              i18nKey={
                allowSmsCommunication
                  ? 'phoneNumberRemovedDialog.smsNotificationsDisabled'
                  : 'phoneNumberRemovedDialog.enableSMSCommunication'
              }
            />
          </p>
        </div>
      </div>
      <Dialog.Actions>
        <Dialog.Close>
          <Button variant="primary" className="btn--solid">
            {t('shared.close')}
          </Button>
        </Dialog.Close>
      </Dialog.Actions>
    </Dialog>
  );
}
