import { Button, Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface IncidentListEmptyMessageProps {
  openNotificationDialog?: (value: boolean) => void;
  notificationRulesEmpty: boolean;
}

export function IncidentListEmptyMessage({
  openNotificationDialog,
  notificationRulesEmpty,
}: IncidentListEmptyMessageProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="flex flex__dir--column flex__ai--center p-xxl w-full h-full text-align-center">
      <Icon
        name="alarm-ringing"
        className="bg-gray-600 m-bottom-xxl"
        size={IconSizeClasses.Large}
      />

      <div className="font-size-xl font-weight-medium m-bottom-m">
        <Trans i18nKey="incidentsTable.noNotificationReceived.title" />
      </div>
      <div className="m-bottom-m">
        {notificationRulesEmpty ? (
          <Trans
            i18nKey="incidentsTable.noNotificationsCreated.hint"
            components={{ br: <br /> }}
          />
        ) : (
          <Trans
            i18nKey="incidentsTable.noNotificationReceived.hint"
            components={{ br: <br /> }}
          />
        )}
      </div>
      {notificationRulesEmpty ? (
        <Button
          variant="primary"
          className="p-left-l"
          onClick={() => openNotificationDialog && openNotificationDialog(true)}
        >
          <Icon
            name="plus"
            size={IconSizeClasses.XSmall}
            className="bg-white m-right-s"
          />

          {t('notificationRules.add')}
        </Button>
      ) : (
        <Button
          size="small"
          variant="secondary"
          onClick={() => navigate('/notifications/notifications-rules')}
        >
          {t('incidentsTable.noNotificationReceived.viewNotificationsRules')}
        </Button>
      )}
    </div>
  );
}
